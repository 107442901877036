import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AbstractServerInteractionService } from '../abstract/abstract.server.interaction.service';
import { Observable } from 'rxjs';
import { AppService } from '../../../../app.service';

@Injectable()
export class ContactsService extends AbstractServerInteractionService {
  protected get url(): {
    // eslint-disable-next-line @typescript-eslint/ban-types
    contacts: Function,
    applyNow: string
  } {
    return {
      contacts: this.appService.getApiUrls().contacts,
      applyNow: this.appService.getApiUrls().applyNow
    };
  }

  constructor(
    protected appService: AppService,
    protected http: HttpClient
  ) {
    super();
  }

  public addContact(managerId: number, contactData) : Observable<any> {
    const {
      firstName,
      lastName,
      phoneNumber: phone,
      email,
      school,
      photo
    } = contactData;
    const base64Image = photo ? photo.split(',')[1] : null;
    let headers = new HttpHeaders();

    headers = headers.append('g-recaptcha-response', contactData.captcha.value);
    return this.http.post(this.url.contacts(managerId), {
      firstName,
      lastName,
      phone,
      email,
      school,
      base64Image
    }, { headers });
  }

  public addContactByInviteId(contactData) {
    const {
      fullName,
      phoneNumber: phone,
      email,
      id: inviteId
    } = contactData;

    return this.http.post(this.url.applyNow, {
      fullName,
      phone,
      email,
      inviteId
    });
  }
}

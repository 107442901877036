import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractServerInteractionService } from '../abstract/abstract.server.interaction.service';
import { Observable} from 'rxjs';

import { ISelectOptionModel } from '../interfaces';
import { AppService } from '../../../../app.service';

@Injectable()
export class SchoolsService extends AbstractServerInteractionService {
  protected get url(): string {
    return this.appService.getApiUrls().schools;
  }

  constructor(protected appService: AppService,
              protected http: HttpClient) {
    super();
  }

  public getSchools(): Observable<ISelectOptionModel[] | any> {
    return this.http.get(this.url);
  }
}
